* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  
  body {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .wrap {
    position: relative;
    max-width: 600px;
    width: 100%;
    padding:40px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
  }
  
  .border_main {
    position: relative;
    padding: 20px;
    margin-top: 20px;
  }
  .large-img{
      display: block;
      margin: 0 auto;
      height: 150px;
  }
  .start{
    text-align: center;
  }
  .login{
    text-align: center;
  } 
    .login__field {
        padding: 20px 0px;	
        position: relative;	
    }
    
    .login__icon {
        position: absolute;
        top: 30px;
        color: rgb(239, 37, 37);
    }
    
    .login__input {
        border: none;
        border-bottom: 2px solid #D1D1D4;
        background: none;
        padding: 10px;
        padding-left: 24px;
        font-weight: 700;
        width: 75%;
        transition: .2s;
    }
    .subm{
      background-color:rgb(239, 37, 37);
      color: white;
      display: block;
      margin: 0 auto;
      margin-top: 20px;
      font-size: 20px;
      border-radius: 20px;
      border: none;
      width: 200px;
      padding: 5px;
    }