#inform{
    display: flex;
    justify-content: center; /* Horizontally center its children */
    align-items: center; /* Vertically center its children */
    height: 100vh; /* Adjust as needed */
  }
  
.information {
    position: relative;
    max-width: 600px;
    width: 100%;
    background-color: white;
    padding:40px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
  }


.describe{
    font-size: 20px;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 80px;
    /* font-size: 40px; */
    width:150px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    background-color: rgb(239, 37, 37);
    color: white;
  }