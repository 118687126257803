* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  
  body {
    min-height: 100vh; 
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .leaveoutput {
  width: 100%;
  background-color: white;
  padding:40px;
  }
 
  .button-corner {
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 9999;
  color:white;
  border: 1px solid rgb(239, 37, 37);
  background-color: rgb(239, 37, 37);
  width: 60px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}
.emoji:hover::before {
  content: "Add Leave";
  position: absolute;
  top: -30px;
  left: -65px;
  color:rgb(239, 37, 37);
  padding: 5px;
}
.h3{
    color: rgb(239, 37, 37);
}
.describe{
    font-size: 20px;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 80px;
    /* font-size: 40px; */
    width:150px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    background-color: rgb(239, 37, 37);
    color: white;
  }
  
  .leavefield {
    position: relative;
    max-width: 400px;
    width: 200%;
   background-color: white;
    padding:40px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
  }
  .inputfield{
    width: 300px;
    height: 50px;
  }
  .some{
    padding-bottom: 20px;
  }
  .submit_button{
    background-color: rgb(239, 37, 37);
    color: white;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 20px;
    border-radius: 20px;
    border: none;
    width: 200px;
    padding: 5px;
  }
  .label{
    font-weight: bold;
  }
  
  .closed {
    position: absolute;
    top: 20px;
    right: 80px;
    font-size: 40px;
    cursor: pointer;
    opacity: .9;
  }