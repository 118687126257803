.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  @media screen and (max-width: 500px) {
    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70vh;
    }
  }