* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.wrapper {
  position: relative;
  max-width: 600px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgb(239, 37, 37),
    rgb(239, 37, 37) 30%,
    transparent 0%,
    transparent
  );
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin: auto;
  min-height: calc(98vh - 0px);
}

.border-align {
  position: relative;
  max-width: 600px;
  width: 100%;
  /* height: 380px; */
  background: white;
  border-radius: 6px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
@media (max-width: 400px) {
  .border-align {
    position: relative;
    max-width: 600px;
    width: 100%;
    /* height: 380px; */
    background: white;
    border-radius: 0px;
    padding: 20px;
    margin-top: 150px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
  }
  #date {
    margin-right: 30px !important;
  }
  .button {
    /* margin-top: 20px; */
    font-size: 15px !important;
    width: 120px !important;
  }
  .submit {
    margin-right: 25px !important;
    width: 120px !important;
    font-size: 15px !important;
  }
}
.buttonof {
  margin-bottom: 20px;
}
.nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-family: Arial, sans-serif;
}
.user-profile {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Arial, sans-serif;
}

.history-container {
  margin-left: auto;
}
.user-photo-container {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid white;
  margin-right: 20px;
  background-color: white;
}

.user-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  display: block;
}
.user-name {
  font-size: 21px;
  color: white;
}
#date {
  margin-right: 240px;
}
.submit {
  background-color: #198754;
  color: white;
  display: block;
  margin: 0 auto;
  /* margin-top: 20px; */
  font-size: 18px;
  border-radius: 20px;
  border: none;
  width: 140px;
  padding: 5px;
}
.button {
  background-color: #a10608;
  color: white;
  display: block;
  margin: 0 auto;
  /* margin-top: 20px; */
  font-size: 18px;
  border-radius: 20px;
  border: none;
  width: 140px;
  padding: 5px;
}
.gt-symbol {
  vertical-align: middle;
  font-size: 30px;
  /* margin-left: 5px;  */
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 20px;
  background-color: #dee2e8;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

a {
  text-decoration: none;
  color: black;
}
.para {
  text-align: center;
  margin-top: 80px;
  color: #908f8f;
}
.loading-contain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}
@media screen and (max-width: 500px) {
  .loading-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
  }
}
.last{
  margin-top: 20px;
  background-color: #f1f1f1;
  height: 200px;
}
.leave{
  background-color: white;
  color: rgb(239, 37, 37);
}

.holidayButton {
  margin: 10px;
  margin-right: 20px;
  text-decoration: none;
  background-color: white;
  color:rgb(239, 37, 37);
  padding: 10px 20px;
  border-radius: 30px;
  display: inline-block;
  transition: background-color 0.3s ease;
  width: 95%;
 

}

/* .holidayButton:hover {
  background-color: rgb(239, 37, 37);
  color: white;
} */

.holidayContent {
  display: flex;
  align-items: center;
}

.holidayImage {
  margin-right: 10px;
}

.holidayText {
  font-weight: bold;
}

.holidayIcon {
  margin-left: 10px;
}

.submit.disabled {
  background-color: #f1f1f1; /* Change to your desired color */
  cursor: not-allowed; /* Change cursor to indicate button is disabled */
  color: #198754;
}
.button.disabled {
  background-color: #f1f1f1; /* Change to your desired color */
  cursor: not-allowed; /* Change cursor to indicate button is disabled */
  color: #a10608;
}