.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px; /* Adjust the max-width to control the size of the modal */
    width: 100%; /* Ensure the modal content takes up the available width */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for depth */
  }
  .modal-button{
    margin: auto;
  }
  
  .modal-button button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(239, 37, 37);
    color: white;
  }
  .message{
    font-weight: bold;
  }